@use '../00_base/charte' as *;

#video {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: -1;
  block-size: calc(100dvh + 2px);
  inline-size: 100dvw;
  object-fit: cover;
}