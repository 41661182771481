@use "sass:color";
@use '../00_base/charte' as *;

button {
  display: flex;
  justify-content: center;
  padding-block: 0.5rem;
  padding-inline: 2rem;
  margin-block-end: 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  background: $white-color;
  border: none;
  box-shadow: 0 0 1rem rgba($black-color, 20%);
  transition-duration: 0.4s;

  &.primary-button {
    color: $white-color;
    background: $primary-color;

    &:active {
      box-shadow: inset 0 0 1rem rgba($black-color, 20%);
    }
  }

  &.secondary-button {
    color: $primary-color;
    background: $white-color;

    &:active {
      box-shadow: inset 0 0 0.5rem rgba($black-color, 20%);
    }
  }

  &.full-size-button {
    inline-size: 100%;
  }

  &:hover {
    box-shadow: 0 0 0.5rem rgba($primary-color, 40%);
  }

  &.disabled {
    opacity: 50%;
  }
}

.installPwaDiv {
  inline-size: 100%;

  button {
    inline-size: calc(100% - 2rem);
    margin-inline: 1rem;
  }

  .adviceText {
    position: absolute;
    margin-block-end: 0.5rem;
    margin-inline: 1rem;
    transform: translateY(-1.5rem);
  }
}