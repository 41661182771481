@use "sass:color";
@use '../00_base/charte' as *;
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

body {
  background: $white-color;
}

body * {
  font-family: 'Cairo', sans-serif;
}
