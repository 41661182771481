@use "sass:color";
@use '../00_base/charte' as *;

h1 {
  font-family: 'Cairo', sans-serif;
  font-size: 1.5rem;
  margin-block-end: 2rem;
  text-align: center;
}

small {
  font-family: 'Cairo', sans-serif;
  font-size: 0.75rem;
  margin-block-end: 0.5rem;
}