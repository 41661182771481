@use "sass:color";
@use "../00_base/charte" as *;

input {

  &[type="text"] {
    display: flex;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    margin: 0;
    margin-block-end: 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.25rem;
    border: 1px solid $primary-color;
    transition-duration: 0.4s;

    &:hover {
    }

    &:active {
    }

    &.disabled {
    }
  }
}
