@use '../00_base/charte' as *;

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  block-size: 100dvh;
  inline-size: 100dvw;

  &-body {
    // position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-block-start: 1rem;
    block-size: 100%;
    inline-size: 100%;

    .workView {
      display: flex;
      flex-direction: column;
      justify-content: center;
      block-size: 100%;

      &:has(.cabinetOpening) {
        &:has(.cabinetOpeningByQrCode) {
          justify-content: end;
        }
      }
    }

    button {
      inline-size: 100%;
      justify-content: center;
    }

    input {
      inline-size: 100%;
    }

    .cabinetOpeningByQrCode {
      form section div {
        position: static !important;
      }
    
      &::before {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        z-index: 2;
        inline-size: 60dvw;
        max-inline-size: 40dvh;
        aspect-ratio: 1/1;
        content: '';
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='0.5rem' stroke-dasharray='50%25 50%25' stroke-dashoffset='25%' stroke-linecap='square'/%3e%3c/svg%3e");
        pointer-events: none;
        transform: translate(-50%, -50%);
      }

      &::after {
        position: absolute;
        inset-block-start: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        z-index: -1;
        content: '';
        background-image: linear-gradient(to bottom, rgba($black-color, 80%), rgba($black-color, 0%), rgba($black-color, 0%), rgba($black-color, 80%))
      }
    }

    .confirmViewMessage {
      max-inline-size: 50dvw;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    inline-size: 100dvw;
    padding-block-start: 2rem;
    background-color: $white-color;
    backdrop-filter: blur(0.25rem);
    border-block-start: 1px solid $white-color;
  }
}